footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 204px;
  
    .content {
      display: flex;
      justify-content: space-between;
      width: 1253px;
  
      .name {
        display: flex;
        justify-content: center;
        font-size: 36px;
        font-weight: 600;
  
        span {
          margin-left: 15px;
        }
      }
  
      .title {
        font-size: 32px;
        font-weight: 500;
      }
  
      .socialMedia {
        display: flex;
        justify-content: space-between;
        width: 288px;
      }
    }
  }