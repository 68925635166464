$outline: #444746;

$background: #FEFFF0;

//Menu
$nav-background: #2d2f31;
$nav-item: #c4c7c5;
$nav-item-hover: #d3e3fd;

// Content
$content-background: #1f1f1f;
$text: #e4e2d9;