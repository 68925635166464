.clubsPreview {
    width: 1234px;
    margin: auto;

    .title {
        position: relative;
        width: 616px;
        margin-top: 80px;
        margin-bottom: 40px;
        font-size: 48px;
        font-weight: 500;

        &::after {
            content: url("~@/icons/Smile.svg"); //  
            position: absolute;
            transform: scale(2);
            top: 20px;
            right: -60px;
        }
    }

    .bigTitle {
        position: relative;
        width: 654px;
        font-size: 80px;
        font-weight: 800;
        margin: auto;
        margin-bottom: 80px;
        z-index: 2;

        &::before {
            content: url("~@/icons/underline.svg");
            position: absolute;
            top: 134px;
            right: 293px;
        }

        &::after {
            content: url("~@/icons/blue-note.svg");
            position: absolute;
            top: 57px;
            right: 139px;
            z-index: -1;
        }
    }

    .clubs {
        height: 1183px;

        .card:nth-child(2n-1) {
            margin-right: 81px;
        }
    }
}