.club {
    display: inline-block;
    position: relative;
    width: 536px;
    height: 536px;
    margin-bottom: 111px;
    box-sizing: border-box;
    padding: 42px 30px;
    z-index: 2;

    &_borderImage {
        position: absolute;
        width: 577px;
        height: 577px;
        top: -27px;
        left: -25px;
        z-index: -1;
    }
    
    &_picture {
        width: 464px;
        height: 293px;
        box-sizing: border-box;
        border: 4px solid black;
        margin-bottom: 32px;
    }

    &_content {
        display: flex;
        justify-content: space-between;
        width: 447px;
    
        .text {
            width: 339px;
        }

        &__link {
            width: 38px;
            height: 38px;
        }

        &__title {
            font-size: 36px;
            font-weight: 800;
        }

        &__description {
            font-size: 24px;
        }
    }
}