@import "@/scssVars/ColorVars";

.header {
  .signboard {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 234px;
    overflow: hidden;
  
    .blotFirst {
      position: absolute;
      top: -40px;
      right: -53px;
    }
  
    .blotSecond {
      position: absolute;
      top: 123px;
      left: -67px;
    }
  
    .logo {
      position: relative;
      display: flex;
      justify-content: end;
      align-items: center;
      width: 414px;
      height: 93px;
      border: 5px solid black;
      padding: 22px 15px;
      box-sizing: border-box;
      background-color: #fff;
  
      &::before {
        content: '';
        display: block;
        position: absolute;
        background-color: $background;
        top: -11px;
        left: -11px;
        border: 3px solid black;
        width: 8px;
        height: 8px;
      }
  
      &::after {
        content: '';
        display: block;
        position: absolute;
        background-color: $background;
        bottom: -11px;
        left: -11px;
        border: 3px solid black;
        width: 8px;
        height: 8px;
      }
  
      &Text {
        margin-left: 15px;
        font-size: 36px;
        font-weight: 600;
      }
    }
  }
  
  .slide {
    position: relative;
    padding-bottom: 148px;
    padding-top: 213px;
  
    .arrowBlock {
      position: absolute;
      top: 100px;
      right: 650px;
  
      div {
        position: relative;
        font-size: 29px;
        font-weight: 600;
        transform: rotateZ(-23deg);
      }
  
      &::before {
        content: url("~@/icons/Smile.svg");
        position: absolute;
        top: 41;
        left: -29px;
      }
  
      &::after {
        content: url("~@/icons/Arrow.svg");
        position: absolute;
        top: 5px;
        right: -84px;
      }
    }
  
    .blotThird {
      position: absolute;
      top: 706px;
      left: -65px;
    }
  
    .wrap {
      display: flex;
      width: 1251px;
      height: 600px;
      margin: auto;
  
      .leftPart {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 50%;
        height: 100%;
  
        .logo {
          position: absolute;
          top: 0;
          left: 0;
        }
  
        .title {
          position: relative;
          font-size: 80px;
          font-weight: 600;
          text-align: end;
          z-index: 2;
  
          &::after {
            content: '';
            position: absolute;
            top: 46px;
            left: 292px;
            width: 341px;
            height: 44px;
            background-color: #FFDC58;
            z-index: -1;
          }
        }

        .slide_button {
          width: 291px;
          height: 97px;
          margin-top: 60px;
        }
      }
  
      .rightPart {
        position: relative;
        width: 50%;
        height: 100%;
  
        img {
          position: absolute;
          top: -6px;
          left: 84px;
        }
      }
    }
  }
}
