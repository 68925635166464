.reviewsPreview {
    width: 1234px;
    margin: auto;
    margin-top: 140px;

    .title {
        position: relative;
        width: 669px;
        margin-bottom: 40px;
        font-size: 48px;
        font-weight: 500;

        &::after {
            content: url("~@/icons/new-vector.svg");
            position: absolute;
            top: 28px;
            right: -114px;
        }
    }

    .reviews {
        position: relative;
        width: 1234px;
        height: 843px;

        .card {
            &:nth-child(1) {
                left: 0px;
                transform: rotateZ(3.5deg);
                z-index: 10;
            }
        
            &:nth-child(2) {
                top: 80px;
                right: 0px;
                transform: rotateZ(-5deg);
                z-index: 11;
            }
        
            &:nth-child(3) {
                top: 300px;
                left: 0px;
                transform: rotateZ(3.5deg);
                z-index: 12;
            }
        
            &:nth-child(4) {
                top: 430px;
                right: 0px;
                transform: rotateZ(3.5deg);
                z-index: 13;
            }
        }
    }
}