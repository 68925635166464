.landingButton {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 97px;
    height: 97px;
    box-sizing: border-box;
    background-color: #BAE6FF;
    font-size: 29px;
    font-weight: bold;
    border: 8px solid black;
    border-radius: 92px;
  
    &::after {
      content: url("~@/icons/Spray.svg");
      position: absolute;
      right: -74px;
      bottom: -57px;
    }
}