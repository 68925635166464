@import "@/scssVars/ColorVars";

.App {
  position: relative;
  box-sizing: border-box;
  background-color: $background;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

//______________________________________________________SCROLLBAR________________________________________________________________________
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(85, 85, 85, 0.4);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 1);
}
