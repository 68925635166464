.nav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 139px;
    background-color: black;

    &Wrap {
        display: flex;
        width: 849px;
    }

    &Item {
        color: white;
        font-size: 48px;
        font-weight: 500;
        flex-grow: 1;
    }
}