.contactUs {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 1235px;
    height: 466px;
    background-color: #fff;
    border: 6px solid black;
    box-shadow: 10px 10px 1px rgba(0, 0, 0, 0.3);
    margin: 100px auto;
    padding: 69px;

    &::before {
        content: url("~@/icons/pink-blot.svg");
        position: absolute;
        top: -130px;
        right: 200px;
    }

    .title {
        font-size: 48px;
        font-weight: 800;
        margin-bottom: 21px;
    }

    .description {
        font-size: 32px;
    }

    &_button {
        width: 352px;
        height: 97px;
        margin-top: 60px;
    }
}