@import '@/scssVars/ColorVars';
@import '@/scssVars/FontVars';

.page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    h1 {
        margin: 0;
        font-family: MoiraiOne;
        font-size: 20rem;
        background: rgb(131,58,180);
        background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    h2 {
        font-size: 4rem;
        font-weight: bold;
        color: $text;
    }

    span {
        font-size: 2rem;
        color: $text;
    }

    a {
        color: rgba(252,176,69,1);
    }
}