.review {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    box-sizing: border-box;
    width: 641px;
    min-height: 253px;
    background-color: #fff;
    border: 6px solid black;
    box-shadow: 10px 10px 1px rgba(0, 0, 0, 0.3);
    padding: 38px 42px;
    font-family: 'Inter';

    &_text {
        font-size: 28px;
        margin-bottom: 20px;
    }
    
    &_author {
        display: flex;

        &__logo {
            width: 92px;
            height: 92px;
            margin-right: 24px;
        }

        &__description {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .name {
                font-size: 28px;
                font-weight: 600;
            }

            .job {
                font-size: 25px;
                color: #545454;
            }
        }
    }
}