@font-face {
    font-family: MoiraiOne;
    src: url(../fonts/MoiraiOne-Regular.ttf);
}

//_________________SpaceGrotesk_________________

@font-face {
    font-family: 'SpaceGrotesk';
    src: url(~@/fonts/Space_Grotesk/static/SpaceGrotesk-Light.ttf);
    font-weight: 300;
    font-style: normal;
  }
  
@font-face {
    font-family: 'SpaceGrotesk';
    src: url(~@/fonts/Space_Grotesk/static/SpaceGrotesk-Regular.ttf);
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'SpaceGrotesk';
    src: url(~@/fonts/Space_Grotesk/static/SpaceGrotesk-Medium.ttf);
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SpaceGrotesk';
    src: url(~@/fonts/Space_Grotesk/static/SpaceGrotesk-SemiBold.ttf);
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SpaceGrotesk';
    src: url(~@/fonts/Space_Grotesk/static/SpaceGrotesk-Bold.ttf);
    font-weight: 700;
    font-style: normal;
}

//_________________Inter_________________

@font-face {
    font-family: 'Inter';
    src: url(~@/fonts/Inter/static/Inter-Thin.ttf);
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url(~@/fonts/Inter/static/Inter-ExtraLight.ttf);
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url(~@/fonts/Inter/static/Inter-Light.ttf);
    font-weight: 300;
    font-style: normal;
}
  
@font-face {
    font-family: 'Inter';
    src: url(~@/fonts/Inter/static/Inter-Regular.ttf);
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url(~@/fonts/Inter/static/Inter-Medium.ttf);
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url(~@/fonts/Inter/static/Inter-SemiBold.ttf);
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url(~@/fonts/Inter/static/Inter-Bold.ttf);
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url(~@/fonts/Inter/static/Inter-ExtraBold.ttf);
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url(~@/fonts/Inter/static/Inter-Black.ttf);
    font-weight: 800;
    font-style: normal;
}